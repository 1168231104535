@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #4a4a4a #1a1a1a; /* Thumb color, Track color */
  scrollbar-width: thin; /* Makes the scrollbar slightly smaller */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  @apply bg-[#222] rounded-lg overflow-hidden transition-transform duration-300 hover:-translate-y-2 hover:shadow-lg;
}

.image {
  @apply object-cover w-full h-64;
}

.title {
  @apply mb-2 text-xl font-semibold;
}

.price {
  @apply mb-4 text-gray-400;
}

.button {
  @apply py-2 w-full font-semibold text-black bg-white rounded-full transition-all duration-300 hover:bg-opacity-90 hover:shadow-md;
}

/* Add fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out;
}

.spinner {
  position: relative;
  width: 35.2px;
  height: 35.2px;
}

.spinner::before,
.spinner::after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  animation: spinner-b4c8mmhg 0.5s backwards, spinner-49opz7hg 1.25s 0.5s infinite ease;
  border: 8.8px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 -52.8px 0 -8.8px #ffffff;
  position: absolute;
}

.spinner::after {
  animation-delay: 0s, 1.25s;
}

/* Scrollbar-Styles */
/* General scrollbar styling */
::-webkit-scrollbar {
  width: 12px;
  height: 12px; /* Adjust height if there’s a horizontal scrollbar */
}

/* Track styling */
::-webkit-scrollbar-track {
  background-color: #1a1a1a;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  /* Remove border-radius */
}

/* Thumb styling */
::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border: 3px solid #1a1a1a;
  /* Remove border-radius */
}

/* Corner styling */
::-webkit-scrollbar-corner {
  background-color: #1a1a1a; /* Ensure solid background */
}


@keyframes spinner-b4c8mmhg {
  from {
    box-shadow: 0 0 0 -8.8px #ffffff;
  }
}

@keyframes spinner-49opz7hg {
  to {
    transform: rotate(360deg);
  }
}